.CTA-btn{
    border-radius: 10px;
    border: 2px solid black;
    font-weight: 900;
    padding: 17px;
    padding-left: 33PX;
    padding-right: 33PX;
    background-color: #0F1035;
    color:white;
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
    height:auto;
    text-align: center;
    margin: 4wh;
    max-width: 300px;
}

@media (min-width: 576px){
    .CTA-btn{
        margin: 0;
    }
}