/*
font-family: "Cairo", sans-serif;
font-family: "Tajawal", sans-serif;
font-family: "Almarai", sans-serif;
font-family: "Noto Kufi Arabic", sans-serif;
font-family: "Alexandria", sans-serif;
*/

*{
    margin: 0;
    padding: 0;
    text-align: right;
}

body{
    background-color: #e6e7ec;
}

.page-content{
    padding-top: 10vh;
}

#tsparticles-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;  
    z-index: -1; 
}

#tsparticles{
    position: relative;
    width: 100%;
    height: 100%;
}

/*hero section*/
#hero-section{
    padding-bottom: 5vh;
    width: 100%;
    min-height: 85vh;
    display:flex;
    flex-direction: column;
}

#main-inner-container{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#main-inner-container p{
    margin-top: 8vh;
    margin-bottom: 50px;
    font-size: 13px;
    margin-right: 2vh;
    margin-left: 2vh;
    font-weight: 400;
    line-height: 1.6;
    font-family: "Alexandria", sans-serif;
}

#main-title{
    color: #0F1035;
    font-family: 'Exo', sans-serif;
    font-size: 40px;
    margin-left: 2vh;
    margin-right: 2vh;
    font-feature-settings: "liga" 1;
}

.robot-img-container{
    overflow: hidden;
}

#robot-img{
    display: none;
}

.color1{
    color: #7EC7DA;
}

.mix-color {
    background: linear-gradient(to left, #7EC7DA, #0F1035);
    -webkit-background-clip: text; /* for Safari */
    color: transparent;
}

/* video section*/
#video-section{
    min-height: 80vh;
    width: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    /*background-color: #7EC7DA;*/
    background-image: url("../img/blurry-gradient-haikei.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#text-container {
    display: flex;
    align-items: center; 
    justify-content: center;
    flex: 1;
    height: 100%;
}

#inner-text-container{
    width: 85%;
}

#inner-text-container h1{
    margin-bottom: 5vh;
    font-family: "Tajawal", sans-serif;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}

#inner-text-container p{
    color: rgb(38, 40, 40);
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6;
    font-family: "Alexandria", sans-serif;
}

#video-container{
    flex: 1;
    max-width: 100%;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#video{
    width: 90%;
    height: auto;
}

/*features section*/
#features-section{
    width: 100%;
    min-height: 85vh;
    padding-top: 10vh;
    background-color: #e6e7ec;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#features-section h1{
    font-family: "Noto Kufi Arabic",system-ui;
    font-weight: 500;
}

#features-container{
    width: 100%;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    margin: 10vh;
    justify-content: space-around;
    align-items: center;
}

.feature{
    width: 250px;
    margin-bottom: 5vh;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.785);
    height: auto;
}

.feature-head{
    width: 100%;
    display: flex;
    align-items: center;
}

.feature-head h4{
    font-family: 'Cairo', sans-serif;
    display: inline-block;
    margin-right: 10px;
}

.feature p{
    font-family: "Almarai", sans-serif;
    line-height: 1.3;
}

.feature .icon-img{
    height: 50px;
    width: 50px;
}

.feature p{
    margin-top: 10px;
    font-size: 12px;
    margin-right: 55px;
}

.feature-hidden {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s, transform 1s;  
}

.feature-active{
    transform: translateY(0px);
    opacity: 1;
}

/*cta seaction*/
#cta-seaction{
    width: 100%;
    min-height: 40vh;
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-image: url("../img/blurry-gradient-haikei.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#cta-seaction h1{
    font-size: 25px;
    text-align: center;
    font-family: "Tajawal", sans-serif;
    margin-bottom: 5vh;
}

/*faq section*/
#faq-section{
    width: 100%;
    min-height: 60vh;
    padding-top: 10vh;
    background-color: #e6e7ec;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#faq-section h1{
    font-family: "Tajawal", sans-serif;
    font-weight: 500;
}

#faq-container{
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 100%;
    min-height: 50vh;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Three columns with equal width */
}

.faq {
    width: 80%;
    align-self: start;
    justify-self: center;
    padding: 1vh;
    margin-bottom: 2vh;
    cursor: pointer;
    overflow: hidden;
    max-height: 80px;
    transition: max-height 0.4s ease, opacity 0.5s ease, box-shadow 0.5s ease;
    border-bottom: 1px solid rgba(0, 0, 0, 0.461);
  }
  
  .faq.open {
    max-height: 500px;
    border-radius: 15px;
    opacity: 1;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  }
  
  .faq h4 {
    margin: 0;
    font-size: 18px;
    opacity: 1;
    font-family: 'Cairo', sans-serif;
    font-weight: 400;
  }
  
  .faq p.answer {
    padding: 10px;
    margin: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
    font-family: "Alexandria", sans-serif;
    font-weight: 400;
    line-height: 1.6;
  }
  
  .faq.open p.answer {
    opacity: 1;
    transition-delay: 0.2s; 
    font-family: "Alexandria", sans-serif;
  }

  @media (min-width: 768px){
        /*hero section*/
        #hero-section{
            flex-direction: row;
            min-height: fit-content;
            padding-top: 15vh;
            padding-bottom: 15vh;
        }
        .half-page-continer{
            min-width: 50%;
            display: flex;

        }
        #main-inner-container{
            margin-right: 10vh;
            align-items: start;
            margin-top: 0px;
        }
        #main-inner-container p{
            font-size: 15px;
            margin-right: 0vh;
            margin-left: 0vh;
            margin-top: 30px;
        }
        #main-title{
            font-size: 40px;
            margin-right: 0vh;
            margin-left: 0vh;
        }
        #robot-img{
            height: 90%;
            width: 100%;
            object-fit:contain;
            object-position: 20%;
            display: unset;
        }
        /*feature section*/
        #features-section{
            min-height: fit-content;
            padding-top: 5vh;
        }
        #features-container{
            flex-direction: row;
            align-items: center;
            min-height: fit-content;
            margin: 7vh;
        }
        .feature{
            margin: 0;

        }
        /*video section*/
        #video-section{
            
            min-height: unset;
            justify-content: center;
            align-items: center;
        }
        #inner-text-container{
            width: 75%;
        }
        #inner-text-container p{
            font-size: 18px;
            margin-bottom: 30px;
        }
        #inner-text-container h1{
            font-size: 35px;
        }
        #video-container{
            margin-top: 0;
        }
        #video{
            width: 75%;
        }
        /*cta seaction*/
        #cta-seaction{
            min-height: fit-content;
            padding-bottom: 5vh;
            padding-top: 5vh;
        }
  }  
@media (min-width: 1025px){
    /*hero section*/
    #hero-section{
        flex-direction: row;
    }
    .half-page-continer{
        min-width: 50%;
    }
    #main-inner-container{
        margin-right: 15vw;
        align-items: start;
    }
    #main-inner-container p{
        font-size: 17.5px;
        margin-right: 0vh;
        margin-left: 0vh;
        margin-top: 30px;
    }
    #main-title{
        font-size: 40px;
        margin-right: 0vh;
        margin-left: 0vh;
    }
    #main-inner-container{
        margin-top: 100px;
    }
    #robot-img{
        height: 80%;
        width: 80%;
        object-fit:contain;
        object-position: 5%;
        display: unset;
    }

    /*video section*/
    #video-section{
        flex-direction: row;
    }
    #inner-text-container{
        width: 70%;
    }
    #inner-text-container h1{
        text-align: right;
        font-size: 28px;
        margin-bottom: 20px;
    }
    #inner-text-container p{
        font-size: 16px;
        line-height: 1.6;
    }
    #video-container{
        max-width: 50%;
    
    }
    #video{
        width: 90%;
    }

    /*features seaction*/
    #features-section{
        min-height: 40vh;
    }
    #features-section h1{
        font-size: 30px;
    }
    #features-container{
        flex-direction: row;
    }
    .feature{
        width: 300px;
    }
    .feature h4{
        font-size: 20px;
    }
    .feature p{
        font-size: 17px;
    }

    /*faq section*/
    #faq-section{
        padding-top: 15vh;
    }
    #faq-section h1{
        font-size: 30px;
    }
    .faq{
        width: 70%;
        max-height: 60px;
        transition: max-height 0.8s ease, opacity 0.5s ease, box-shadow 0.5s ease;
    }
    .faq h4{
        font-size: 20px;
    }
    .faq p{
        font-size: 16px;
    }
}

@media (min-width: 1300px){
    /*hero section*/
    #main-title{
        font-size: 55px;
    }
    /*video section*/
    #inner-text-container p{
        font-size: 18px;
    }
}