#contact-us-page{
    padding-top: 10vh;
    padding-bottom: 5vh;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #ffffff, #e6e7ec);
}

#contact-box-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    margin-top: 10vh;
    width: 90%;
    background-color:aliceblue;
    border-radius: 15px;
    border: 0.1px groove rgb(167, 166, 166);
}

#inner-contact{
    width: 80%;
}

#inner-contact h1{
    margin-bottom: 3vh;
    font-family: "Tajawal", sans-serif;
    font-display: block;
}

#inner-contact p{
    font-family: "Noto Kufi Arabic", sans-serif;
    font-display: block;
}

.input{
    width: 100%;
    height: 30px;
    margin-top: 4vh;
    padding: 1vh;
    background-color:inherit;
    border: none;
    border-bottom: 1px solid gray;
    font-size: 20px;
}

.input::placeholder {
    font-size: 20px; 
    
}

#send-btn{
    width: 100%;
    height: 50px;
    margin-top: 5vh;
    margin-bottom: 5vh;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    background-color: #0F1035;
    color: white;
    border: none;
    border-radius: 15px;
    font-family: "Noto Kufi Arabic", sans-serif;
}

#contact-info{
    color: white ;
    background-color: #375486;
    margin-top: -1vh;
    left: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;
    border-radius: 0px 0px 15px 15px;
    width: 90%;
    height: 60px;
}

.contact-icon-container{
    width: fit-content;
    font-family: 'Arial', 'Helvetica', sans-serif;
}

.contact-icon{
    color: white;
    font-size: 22px;
}


/*msg*/
.msg-background-blur{
    width: 100%;
    height: 100vh;
    position: absolute;
    backdrop-filter: blur(1px); /* Adjust the blur intensity as needed */
    background-color: rgba(255, 255, 255, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
}
.msg-container{
    border-radius: 15px;
    background-color: blueviolet;
    width: fit-content;
    position: absolute;
    padding: 20px;
    top: 30%;
}

@media (min-width: 768px){
    #contact-box-container{
        width: 70%;
    }
    #contact-info{
        width: 70%;
    }
}

@media (min-width: 992px){
    #contact-us-page{
        flex-direction: row;
        align-items: unset;
    }   
    #contact-box-container{
        width: 50%;
        height: 70vh;
    }
    #inner-contact{
        width: 65%;
    }
    #contact-info{
        width: 100px;
        height: 70vh;
        position: relative;
        flex-direction: column;
        border-radius: 15px 0px 0px 15px;
        margin-top: 10vh;
    }
}

@media (min-width: 1200px){
    #contact-box-container{
        width: 45%;
    }
}