#footer{
    height: 25vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color:  #e6e7ec;
}

#footer-logo-section{
    height: 100%;
    width: fit-content;
    margin-right: 2vh;
}

#footer-logo-container{
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-top: 2vh;
}

#footer-logo{
    height: 100%;
    width: 100%;
    object-fit:contain;
}

#footer-content-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#footer-link-section{
    height: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-link{
    color: black;
    text-decoration: none;
    font-family: 'Cairo', sans-serif;
    margin-right: 1vh;
    margin-left: 1vh;
}

#footer-socail-media-secation{
    height: 100%;
    display: flex;
    flex-direction: row;
}

#footer-socail-media-secation a{
    color: black;
}

.icon{
    font-size: 25px;
    margin-left: 5vh;
    margin-top: 5vh;
}

#riguts{
    background-color: #e6e7ec;
    margin: 0;
}

#riguts p{
    margin-bottom: 2vh;
    text-align: center;
    font-size: 14px;
}

@media (min-width: 992px){
    #footer-logo-section{
        margin-right: 15vh;
    }   
    #footer-logo-container{
        height: 80px;
        width: 80px;
    }
    #footer-link-section{
        min-width: 500px;
        flex-direction: column;
    }
    .footer-link{
        margin: 1vh;
    }
    #footer-content-container{
        flex-direction: row;
        justify-content: start;
    }
}