

*{
    margin: 0;
    padding: 0;
    text-align: right;
    box-sizing: border-box;
}

#header{
  width: 100%;
  height: 10vh;
  min-height: 40px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.5s ease; 
}

#logo-container {
  height: 100%;
  overflow: hidden;
  margin-right: 35px;
  display: flex;
  align-items: center;
}

#header .logo {
  height: 50px;
  width: 60px;
  object-fit:contain;
}

#mobile-nav-toggle{
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 4vh;
  left: 1rem;
  z-index: 9999;
  border: none;
  width: 1.5rem;
  aspect-ratio: 1;
}

.btn-open{
  background: url("../img/navMenuIcon.png");
}

.btn-close{
  background: url("../img/icons8-close-50.png");
}

#header-cta-button{
  display: none;
}

#header.scrolled {
  background-color: #DCF3F2;
}

#menu-nav{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
}

#menu-nav ul{
  display: flex;
  flex-direction: column;
  list-style: none;
  position: fixed;
  z-index: 1000;
  background: hsla(233, 88%, 0%, 0.5);
  backdrop-filter: blur(1.5rem);
  padding: min(15vh,10rem) 10px;
  inset: 0 30% 0 0;
  transition: transform 350ms ease-out;
}

.navShow{
  transform: translateX(0%);
}

.navHide{
  transform: translateX(-100%);
}

#menu-nav ul li{
  margin-right: 20px;
  font-family: 'Cairo', sans-serif;
  margin-top: 1em;
}

.link{
  color: black;
  text-decoration: none;
}

@media (min-width: 768px){
#header{
  justify-content: unset;
}  
#menu-nav{
    margin-left: 100px;
}
#menu-nav ul{
    flex-direction: row;
    background-color: unset;
    position: unset;
    inset: unset;
    padding: 0px;
    background: unset;
    backdrop-filter: unset;
    transform: unset;
}
#menu-nav ul li{
    margin-right: 20px;
    margin-top: 0px;
}
#logo-container {
  overflow: hidden;
  margin-right: 25px;
  margin-left: 50px;
  height: 100%;
}
#header .logo {
  height: 70%;
  width: 100%;
  object-fit:contain;
}
#header-cta-button{
  display: inline;
}
#mobile-nav-toggle{
  display: none;
}
}

@media (min-width: 1025px){ 
#menu-nav{
    margin-right: 300px;
    margin-left: 100px;
}
#menu-nav ul li{
    margin-right: 20px;
    margin-top: 0px;
}
#logo-container {
  height: 100%;
  overflow: hidden;
  margin-right: 8%;
  margin-left: 0;
}
}

@media (min-width: 1300px){
  #logo-container{
    margin-right: 15%;
  }
  #menu-nav{
    margin-right: 35vw;
  }

}

