/*
font-family: "Cairo", sans-serif;
font-family: "Tajawal", sans-serif;
font-family: "Almarai", sans-serif;
font-family: "Noto Kufi Arabic", sans-serif;
font-family: "Alexandria", sans-serif;
*/

#cta-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../img/ctaBackground.png");
    background-repeat: no-repeat;
    background-size:cover;
}

#cta-background-img{
    position: absolute;
}

#cta-title{
    margin-top: 10vh;
    font-size: 30px;
    font-family: "Tajawal", sans-serif;
    text-align: center;
}

#form-container{
    margin-top: 7vh;
    width: 50%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#set-meeting-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 350px;
    height: auto;
    padding: 2vh;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.699);
    background-color: white;
}

#set-meeting-form h2{
    font-family: "Cairo", sans-serif;
    text-align: center;
}

#set-meeting-form input{
    margin-top: 5vh;
    border: none;
    border-bottom: 1px solid black;
    background-color: inherit;
    width: 60%;
    padding: 0.8vh;
}

#dateInput{
    width: 100%;
}

#set-meeting-form input::placeholder{
    font-family: "Alexandria", sans-serif;   
}

#set-meeting-form button{
    color: white;
    background-color: #0F1035;
    margin-top: 5vh;
    width: 150px;
    height: 40px;
    text-align: center;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.blurry-container{
    width: 100%;
    height: 100vh;
    position: absolute;
    backdrop-filter: blur(1px); /* Adjust the blur intensity as needed */
    background-color: rgba(255, 255, 255, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
}

.message-container{
    position: absolute;
    top: 40%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: fit-content;
    max-width: 300px;
    height: fit-content;
    padding: 15px;
    font-size: 17px;
    font-family: "Noto Kufi Arabic", sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.699);
}

#success-message-container{
    background-color: #3AA655;
}

#success-message-container i{
    color: white;
    margin-left: 10px;
}

#fail-message-container{
    background-color: #FF5050;
}

#fail-message-container i{
    color: white;
    margin-left: 10px;
}

@media (min-width: 700px){
    #cta-title{
        font-size: 50px;
    }
    #form-container{
        width: 70%;
    }
    #set-meeting-form{
        width: 400px;
    }
}