/*
font-family: "Cairo", sans-serif;
font-family: "Tajawal", sans-serif;
font-family: "Almarai", sans-serif;
font-family: "Noto Kufi Arabic", sans-serif;
font-family: "Alexandria", sans-serif;
*/
.section-title{
    font-size: 18px;
    color: #375486;
    font-family: "Almarai", system-ui;
    margin-bottom: 3vh;
}

.section-content-title{
    font-size: 25px;
    font-family: "Noto Kufi Arabic",system-ui;
    margin-bottom: 2vh;
    font-weight: 500;
}


#about-us-page{
    padding-top: 10vh;
    min-height: 20vh;
    background-color: #e6e7ec;
}

#about-hero-section{
    height: 70vh;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
}

#inner-hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 70vh;
    position: relative;    
}

#inner-hero h3{
    margin-bottom: 3vh;
}

#inner-hero h1{
    font-size: 25px;
    margin-bottom: 2vh;
    text-align: center;
    width: 85%;
}

#inner-hero p{
    margin-bottom: 10vh;
    width: 80%;
    text-align: center;
    font-family: "Alexandria", sans-serif;
    line-height: 1.7;
    font-weight: 400;
}

#gear-img{
    height: 60px;
    position: absolute;
    right: 0%;
    bottom: 0%;
}

#worker-robot-img{
    height: 100px;
    position:absolute;
    left: -10%;
    bottom: 20%;
}

/*our story*/
#our-story{
    min-height: 70vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#inner-story{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
}

#text-story{
    width: 100%;
}

#text-story p{
    font-size: 16px;
    margin-bottom: 3vh;
    font-family: "Alexandria", sans-serif;
    line-height: 1.7;
    font-weight: 400;
}

#img-story-container{
    width: 100%;
    height: 100%;
}

#story-img{
    width: 100%;
}

/*mission section*/
#mission-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    position: relative;
    min-height: 50vh;
    margin-top: 7vh;
    padding-top: 7vh;
    padding-bottom: 5vh;
    background-image: url("../img/blurry-gradient-haikei.png");
    background-repeat: no-repeat;
    background-size: cover;
}

#mission-section p{
    margin-top: 2vh;
    width: 80%;
    font-size: 16px;
    text-align: right;
    font-family: "Alexandria", sans-serif;
    line-height: 1.7;
    font-weight: 400;
}

#write-robot-img{
    position: absolute;
    right: 0%;
    top: 5%;
    height: 80px;
}

/*team section*/
#team-section{
    min-height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 5vh;
}

#inner-team-section{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
}

#team-img-container{
    width: 100%;
    height: 100%;
}

#team-img{
    width: 100%;
}

#text-team-container{
    width: 100%;
}

#text-team-container h3{
    margin-top: 5vh;
}

#text-team-container p{
    font-size: 16px;
    font-family: "Alexandria", sans-serif;  
    line-height: 1.7;
    font-weight: 400;
}
/*cta section*/
#cta-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 30vh;
    padding-bottom: 5vh;
}

#cta-section h1{
    margin-top: 10vh;
    margin-bottom: 2vh;
    font-size: 18px;
    text-align: center;
}

@media (min-width: 768px){
    .section-title{
        font-size: 19px;
    }
    .section-content-title{
        font-size: 25px;
    }

    /*hero section*/
    #about-hero-section{
        min-height: fit-content;
    }
    #inner-hero h1{
        font-size: 35px;
    }
    #worker-robot-img{
        height: 150px;
        left: 1%;
        bottom: 15%;
    }
    #gear-img{
        height: 80px;
        right: 16%;
        bottom: 7%;
    }

    /*our story*/
    #our-story{
        min-height: fit-content;
        padding-top: 10vh;
    }
    #inner-story{
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 70%;
    }
    #text-story{
        height: 70%;
        width: 40%;
    }
    #text-story p{
        font-size: 15px;
    }
    #img-story-container{
        width: 48%;
        margin-right: 2vw;
    }
    #story-img{
        height: 90%;
    }

    /*mission section*/
    #mission-section{
        min-height: fit-content;
    }
    #mission-section p{
        width: 65%;
        font-size: 17px;
        text-align: center;
    }
    #write-robot-img{
        right: 8%;
        top: 5%;
        height: 150px;
    }  

    /*team section*/
    #team-section{
        min-height: fit-content;
    }
    #inner-team-section{
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 70%;
    }
    #team-img-container{
        width: 48%;
    }
    #team-img{
        width: 90%;
        margin: 10px;
    }
    #text-team-container{
        width: 48%;
    }
    #text-team-container h3{
        margin-top: 0;
    }
    #text-team-container p{
        font-size: 14px;
        width: 90%;   
    }

    /*cta section*/
    #cta-section h1{
        margin-bottom: 5vh;
        font-size: 25px;
    }
}


@media (min-width: 1025px){
    #write-robot-img{
        right: 20%;
    }
    /*team section*/
    #inner-team-section{
        width: 67%;
        min-width: 1000px;
        max-width: 1100px;
    }
    #team-img-container{
        width: 50%;
    }
    #team-img{
        width: 100%;
    }
    #text-team-container{
        margin-right: 5vh;
    }
    #text-team-container p{
        font-size: 18px;
    }
    /*story section*/
    #inner-story{
        width: 67%;
        min-width: 1000px;
        max-width: 1100px;
    }
    #text-story p{
        font-size: 18px;
    }
}